import React, { useState, useEffect, useRef } from "react";
import DataView from "../../../../UI/Dashboard/DataView";
import { useParams, useNavigate } from "react-router-dom";
import FormGroup from "../../../../UI/Form/FormGroup";
import ErrorField from "../../../../UI/Form/ErrorField";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "../../../../UI/Form/Button";
import ContentLayout from "../../../../Common/ContentLayout";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import JourneyAjax from "../../../../../helpers/JourneyAjax";
import axios from "axios"
import Select from 'react-select'

export default function AddNewRM() {
  const urlParams = useParams();
  const navigate = useNavigate();
  const userValidToken = localStorage.getItem("userToken");
  const [microMarketOption,setMicroMarketOption] = useState([])
  const [microRelationalManager,setMicroRelationalManager] = useState([])
  const [selectedMicroMarket,setSelectedMicroMarket] = useState([])
  const [rmtype, setRmType] = useState("")
  const [selectedManager, setSelectedManager] = useState("")
  async function getMicroMarkets() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_CRM_URL}/micro-markets`)
      if (response.status === 200) {
        const microMarketList = []
        response?.data?.data?.map((item)=>{
          microMarketList.push({label:item?.name,value:item?.id})
        })
        setMicroMarketOption(microMarketList)
      }
    } catch (err) {
      console.error(err.response.message)
    }
  }

  async function getRelationalManager() {
    try {
      const filter = {
        where:{or:[{type:'teamLead'},{type:'manager'}]}
      }
      const response = await axios.get(`${process.env.REACT_APP_JOURNEY_URL}/relation-managers?filter=${JSON.stringify(filter)}`)
      if (response.status === 200) {
        setMicroRelationalManager(response.data.data.relationManagers ?? [])
      }
    } catch (err) {
      console.error(err.response.message)
    }
  }

  useEffect(()=>{
    getMicroMarkets()
    getRelationalManager()
  },[])
  const addRM = async (values, event) => {
    let microMarkets = []
    if(selectedMicroMarket && selectedMicroMarket.length > 0){
      microMarkets = selectedMicroMarket?.map((item)=>{
          return item.value
        })
    }else{
      toast.error('Micro market is required')
      return;
    }
    if(rmtype == "") toast.error("Type is required");

    const data = {
      name: values.name,
      email: values.email,
      mobile: values.mobile,
      microMarket:microMarkets,
      type:rmtype ?? "",
      managerId:selectedManager ?? ""
    }
    const update = await JourneyAjax({
      url: `/relation-managers`,
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      "x-user": userValidToken || null,
      method: "POST",
      data: data,
      token: true,
      loader:true
    });
    if (update.data.error) {
      toast.error("Something Went Wrong Please Check");
    }
    if (update.status === 200) {
      toast.success("RM Added Successfully");
      setTimeout(function() {
        navigate("/admin/property-journey/relation-manager");
      }, 500);
    }
  };
  const handleMicroMarkets = (microMarket) => {
    if(Array.isArray(microMarket)){
      setSelectedMicroMarket([...microMarket])
    }else{
      setSelectedMicroMarket([microMarket])
    }
  }
  const handleManager = (e) => {
    setSelectedManager(e.target.value)
  }
  return (
    <ContentLayout title="Relation Manager">
      <Helmet>
        <title>Add New RM</title>
      </Helmet>
      <DataView title="Infomation">
        <Formik
          initialValues={{
            name: "",
            email: "",
            mobile: "",
            microMarket:[]
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            email: Yup.string()
              .email("Invalid email")
              .required("Email is required"),
            mobile: Yup.string().required("Mobile Number is required"),
            microMarket:Yup.array().required("Micro market is required")
            // manager:Yup.string().required("Manager is required")
          })}
          onSubmit={(values, event) => {
            addRM(values, event);
          }}
        >
          <Form>
            <div className="row">
              <div className="col-md-6">
                <FormGroup label="Name" required>
                  <Field
                    placeholder="Name"
                    name="name"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="name" />
                  </ErrorField>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup label="Email" required>
                  <Field
                    placeholder="Email"
                    type="email"
                    name="email"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="email" />
                  </ErrorField>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup label="Mobile" required>
                  <Field
                    placeholder="Mobile Number"
                    type="string"
                    name="mobile"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="mobile" />
                  </ErrorField>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup label="Micro Market" required>
                  <div className='col-md-12'>
                    {microMarketOption !== null ?
                      <Select
                        options={microMarketOption}
                        onChange={handleMicroMarkets}
                        value={selectedMicroMarket}
                        closeMenuOnSelect={false}
                        isMulti={rmtype !== "member" ? true : false}
                      /> : 'Loading...'}
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup label="type" required>
                  <Field
                    as="select"
                    name="type"
                    className="form-select"
                    value={rmtype} onChange={(e)=>{
                      if(e.target.value){
                        setRmType(e.target.value)
                        setSelectedManager("")
                        setSelectedMicroMarket([])
                      }
                    }}
                  >
                           <option value="">Select</option>
                           <option value="manager">Manager</option>
                           <option value="teamLead">Team lead</option>
                           <option value="member">Member</option>
                  </Field>
                  <ErrorField>
                    <ErrorMessage name="type" />
                  </ErrorField>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup label="Manager" >
                {rmtype == "manager"  ? <select className='form-select' disabled></select> :
                        <select name="type" className='form-select' value={!selectedManager ? "None" : selectedManager} onChange={handleManager} >
                          <option value="" >None</option>
                          {microRelationalManager && microRelationalManager.map((data) => (
                            <>
                            {rmtype == "teamLead" && data.type == "manager" && 
                            <option key={data.id} value={data.id}>{data.name}</option>
                            }
                            {rmtype == "member" && data.type == "teamLead" && 
                            <option key={data.id} value={data.id}>{data.name}</option>
                            }
                            </>
                          ))}
                        </select>
                      }
                </FormGroup>
              </div>
            </div>

            <div className="col-md-2 d-grid">
              <Button type="submit">Add</Button>
            </div>
          </Form>
        </Formik>
      </DataView>
    </ContentLayout>
  );
}
