import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TableLoader from "../../../../UI/Dashboard/TableLoader";
import DataView from "../../../../UI/Dashboard/DataView";
import TableContainer from "../../../../UI/Dashboard/TableContainer";
import { Helmet } from "react-helmet-async";
import ContentLayout from "../../../../Common/ContentLayout";
import EditButton from "../../../../UI/Dashboard/EditButton";
import JourneyAjax from "../../../../../helpers/JourneyAjax";
import PaginationContainer from "../../../../UI/Dashboard/PaginationContainer";
import Pagination from "../../../../UI/Dashboard/Pagination";
import DataLoader from "../../../../UI/Dashboard/DataLoader";
import Popup from "../../../../UI/Dashboard/Popup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormGroup from "../../../../UI/Form/FormGroup";
import ErrorField from "../../../../UI/Form/ErrorField";
import Button from "../../../../UI/Form/Button";
import { toast } from "react-toastify";

export default function SellerList() {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams()
  const [totalRow, setTotalRows] = useState(null)
  const [userToken, setUserToken] = useState(null);
  const [dataList, setDataList] = useState(null);
  const [loader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(searchParams.get('page'));
  const currentPerPage = 10;
  
  useEffect(() => {
    const getResponse = setTimeout(function(){  
    (async () => {
      setLoader(true);
      const skip = searchParams.get('page') === 1 ? 10 : ((searchParams.get('page')) * currentPerPage);
      const journeyData = await JourneyAjax({
        url: `/pj?filter={"skip": ${skip},"limit": ${currentPerPage}}`,
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        "x-user": userValidToken || null,
        token: true,
      });
      if (journeyData && journeyData.data && journeyData.data.data) {
        setDataList(journeyData.data.data);
        setTotalCount(journeyData.data.data.totalCount);
        setTotalRows(journeyData.data.data.totalCount / 10)
        setCurrentPage(searchParams.get('page'))
        setLoader(false);
      }
    })();
  },100) 
  return()=>{
    clearInterval(getResponse)
}
  }, [searchParams.get('page')]);

  // xpx1np4ctk

  const userValid = async (values, event) => {
    const data = {
      ...(values.slug && { slug: values.slug }),
      username: values.username,
    };

    const formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }

    const checkUser = await JourneyAjax({
      url: `/admin/${data.username}`,
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      "x-user": data.username,
      token: true,
    });

    if (checkUser && checkUser.status === 200) {
      toast.success("Continue your journey");
      setUserToken(checkUser);
      localStorage.setItem("userToken", checkUser.data.username);
      // setTimeout(function() {
      setOpen(false);
      // }, 1000);
    }

    if (checkUser && checkUser.status === 403) {
      toast.error("Username is not valid");
    }
  };

  const userValidToken = localStorage.getItem("userToken");
  return (
    <>
      <ContentLayout title="Seller" addNew="/admin/property-journey/seller">
        <div className="row">
          <Helmet>
            <title>Seller Journey</title>
          </Helmet>
          {loader ? (
            <DataLoader />
          ) : (
            <DataView title="Seller List">
              <TableContainer>
                <thead>
                  <tr>
                    <td className="text-center">User Type</td>
                    <td className="text-center">Username</td>
                    <td className="text-center">Journey Status</td>
                    <td className="text-center">Edit</td>
                  </tr>
                </thead>
                <tbody>
                  {dataList == null ? (
                    <TableLoader cols={6} />
                  ) : dataList.length === 0 ? (
                    <tr>
                      <td colSpan={6} className="text-center">
                        Data not available
                      </td>
                    </tr>
                  ) : (
                    dataList.properties.map((data, index) => (
                      <tr key={data._id}>
                        <td className="text-center">{data.userType}</td>
                        <td className="text-center">{data.user ? data.user.name : ""}</td>
                        <td className="text-center">{data.status}</td>
                        <td className="text-center">
                          {userValidToken ? (
                            <EditButton>{`/admin/property-journey/seller/${data.token}`}</EditButton>
                          ) : (
                            <div
                              className="btn btn-primary"
                              onClick={() => setOpen(true)}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </TableContainer>

              <PaginationContainer count={totalCount}>
                <Pagination totalPage={totalRow} url='/admin/property-journey/seller' currentPage={searchParams.get('page')}  />
              </PaginationContainer>

              {/* <div className="pagination-seller-journey">
                {Array.from({ length: totalPages }, (_, index) => (
                  <a
                    key={index + 1}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : "not-active"
                    }`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </a>
                ))}
              </div> */}
            </DataView>
          )}
        </div>
      </ContentLayout>

      <Popup open={open} close={setOpen}>
        <Formik
          initialValues={{
            username: "",
          }}
          onSubmit={(values, event) => {
            userValid(values, event);
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup label="Username" required>
                <Field
                  placeholder="Username"
                  name="username"
                  className="form-control"
                />
                <ErrorField>
                  <ErrorMessage name="username" />
                </ErrorField>
              </FormGroup>

              <div className="d-grid">
                <Button type="submit">Enter User ID</Button>
              </div>
            </Form>
          )}
        </Formik>
      </Popup>
    </>
  );
}
