import React, {useEffect,useRef, useState} from 'react'
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../../Common/ContentLayout';
import JourneyAjax from "../../../../../helpers/JourneyAjax";
import moment from 'moment'
import Chart from 'chart.js/auto';
import { Pie,Bar } from "react-chartjs-2"
import { toast } from 'react-toastify';
import Popup from "../../../../UI/Dashboard/Popup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormGroup from "../../../../UI/Form/FormGroup";
import ErrorField from "../../../../UI/Form/ErrorField";
import Button from "../../../../UI/Form/Button";


const BarChart = ({label,data}) => {
    const colors = [
        'rgba(54, 162, 235, 0.6)', // Blue
        'rgba(255, 99, 132, 0.6)', // Red
        'rgba(75, 192, 192, 0.6)', // Green
        'rgba(153, 102, 255, 0.6)', // Purple
        'rgba(255, 159, 64, 0.6)', // Orange
        'rgba(255, 206, 86, 0.6)', // Yellow
      ];
    return (
        <div>
            <Bar
                data={{
                    // Name of the variables on x-axies for each bar
                    labels: label, // Reverse if you want latest date on the right
                    datasets: [
                          {
                            label: 'Buyer Lead',
                            backgroundColor: colors[2],
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(75, 192, 192, 0.8)',
                            hoverBorderColor: 'rgba(75, 192, 192, 1)',
                            data: data.buyerLeadCounts // Reverse to match dates
                          },
                          {
                            label: 'Seller Lead',
                            backgroundColor: colors[3],
                            borderColor: 'rgba(153, 102, 255, 1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(153, 102, 255, 0.8)',
                            hoverBorderColor: 'rgba(153, 102, 255, 1)',
                            data: data.sellerLeadCounts // Reverse to match dates
                          },
                          {
                            label: 'Buyer Lead Visit Done',
                            backgroundColor: colors[4],
                            borderColor: 'rgba(255, 159, 64, 1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255, 159, 64, 0.8)',
                            hoverBorderColor: 'rgba(255, 159, 64, 1)',
                            data: data.buyerLeadVisitDoneCounts // Reverse to match dates
                          },
                          {
                            label: 'Seller Lead Visit Done',
                            backgroundColor: colors[5],
                            borderColor: 'RGB(255, 181, 0)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'RGB(255, 181, 0)',
                            hoverBorderColor: 'RGB(255, 181, 0)',
                            data: data.sellerLeadVisitDoneCounts // Reverse to match dates
                          }
                    ]
                }}
                // Height of graph
                height={400}
                // width={2000}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    // The y-axis value will start from zero
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                    legend: {
                        labels: {
                            fontSize: 15,
                        },
                    },
                    layout: {
                        padding: 20
                    }
                }}
            />
        </div>
    );
};

const PieChart = ({label,data}) => {
    return (
        <div>
            <Pie
                data={{
                    // Name of the variables on x-axies for each bar
                    labels: label, // Reverse if you want latest date on the right
                    datasets: [
                        {
                            label: 'Count',
                            backgroundColor: [
                                'rgb(255, 99, 132)',
                                'rgb(54, 162, 235)',
                                'rgb(255, 205, 86)'
                              ],
                            data: data // Reverse to match dates
                          }
                    ]
                }}
                // Height of graph
                height={350}
                // width={2000}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    // The y-axis value will start from zero
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                    legend: {
                        labels: {
                            fontSize: 15,
                        },
                    },
                }}
            />
        </div>
    );
};

const dateFormate = (date) => {
    const dateObject = new Date(date)
    // Get year, month, and day from the date object
    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObject.getDate()).slice(-2);

    const isoDate = `${year}-${month}-${day}`;
    return isoDate;
}

export default function DataList() {
    const [label, setLabel] = useState([])
    const [data, setData] = useState({})
    const [startDate, setStartDate] = useState(dateFormate(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)))
    const [endDate, setEndDate] = useState(dateFormate(new Date()))
    const [pieData, setPieData] = useState([])
    const [leadData, setLeadData] = useState([])
    const [dateFilter, setDateFilter] = useState(false)
    const [searchParams] = useSearchParams()
    const validUser = localStorage.getItem("userToken")
    const [open, setOpen] = useState(false);
    const [userToken, setUserToken] = useState(null);
    
    useEffect(()=>{
        (async()=>{
        if(validUser){
        const result = await JourneyAjax({
            url: `/miscellaneous/admin/dailyAnalytics?startDate=${startDate}&endDate=${endDate}`,
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            "x-user": validUser,
            token: true,
            loader:true
          });
          if(result && result.data && result.data.data && result.data.data.length > 0){
            const jsonData = result.data.data
            let signUpCount = 0
            let brokerApprovedCount = 0
            let totalBuyerLead = 0
            let totalSellerLead = 0
            let totalBuyerLeadVisitDone = 0
            let totalSellerLeadVisitDone = 0
            let dates = []
            let buyerLeadCounts =  []//jsonData.map(data => data.buyerLeadCount.count);
            let sellerLeadCounts = []//jsonData.map(data => data.sellerLeadCount.count);
            let buyerLeadVisitDoneCounts = []//jsonData.map(data => data.buyerLeadVisitDoneCount.count);
            let sellerLeadVisitDoneCounts = []//jsonData.map(data => data.sellerLeadVisitDoneCount.count);
            jsonData.map((data)=>{
                signUpCount += data.signUpCount.count
                brokerApprovedCount += data.brokerApprovedCount.count
                totalBuyerLead += data.buyerLeadCount.count
                totalSellerLead += data.sellerLeadCount.count
                totalBuyerLeadVisitDone += data.buyerLeadVisitDoneCount.count
                totalSellerLeadVisitDone += data.sellerLeadVisitDoneCount.count
                dates.push(data.date)
                buyerLeadCounts.push(data.buyerLeadCount.count)
                sellerLeadCounts.push(data.sellerLeadCount.count)
                buyerLeadVisitDoneCounts.push(data.buyerLeadVisitDoneCount.count)
                sellerLeadVisitDoneCounts.push(data.sellerLeadVisitDoneCount.count)
            })
            const data = {
                buyerLeadCounts:buyerLeadCounts,
                sellerLeadCounts:sellerLeadCounts,
                buyerLeadVisitDoneCounts:buyerLeadVisitDoneCounts,
                sellerLeadVisitDoneCounts:sellerLeadVisitDoneCounts
            }
           setLabel(dates)
           setData(data)
           setPieData([signUpCount,brokerApprovedCount])
           setLeadData([totalBuyerLead,totalSellerLead,totalBuyerLeadVisitDone,totalSellerLeadVisitDone])
           setDateFilter(false)
          }
        }else{
            setOpen(true)
        }
        })()
    },[dateFilter,userToken])

    const userValid = async (values, event) => {
        const data = {
          ...(values.slug && { slug: values.slug }),
          username: values.username,
        };
    
        const formData = new FormData();
    
        for (var key in data) {
          formData.append(key, data[key]);
        }
    
        const checkUser = await JourneyAjax({
          url: `/admin/${data.username}`,
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          "x-user": data.username,
          token: true,
        });
    
        if (checkUser && checkUser.status === 200) {
          toast.success("User validation successfully");
          setUserToken(checkUser);
          localStorage.setItem("userToken", checkUser.data.username);
          setOpen(false);
        }
    
        if (checkUser && checkUser.status === 403) {
          toast.error("Username is not valid");
        }
      };
    
  // Function to handle date changes
  const handleDateChange = (date, type) => {
    if(date == "Invalid Date"){
     toast.error("Date is required")
    }else{
    const formateDate = dateFormate(date)
    if (type === 'start') {
        if(endDate < formateDate){
            toast.error("start date must be less than end date")
          }else{
            setStartDate(formateDate);
          }
    } else if (type === 'end') {
      if(startDate > formateDate){
        toast.error("End date must be greater than start date")
      }else{
        setEndDate(formateDate);
      }
    }
   }
  };

    return (
        <>
        {validUser ?
    <ContentLayout title='' >
        <div className="row">
        <Helmet>
            <title>Data Chart</title>
        </Helmet>
                <div className='row d-flex justify-content-between align-items-start'>
                    <div className='col-md-6'><h1 className='fw-bold'>Sign up and broker approved count</h1></div>
                    <div className='form-group col-md-6 d-flex  justify-content-end '>
                        <div className='form-group col-md-5 px-2'>
                            <label ><h5 className='fw-bold'>Start Date</h5></label>
                            <input type="date" className='form-control mt-2' value={startDate} max={endDate} onChange={(e) => handleDateChange(moment(e.target.value).toDate(), 'start')} />
                        </div>
                        <div className='form-group col-md-5 px-2'>
                            <label><h5 className='fw-bold'>End Date</h5></label>
                            <input type="date" className='form-control mt-2' value={endDate} min={startDate} max={dateFormate(new Date())} onChange={(e) => handleDateChange(moment(e.target.value).toDate(), 'end')} />
                        </div>
                        <div className='form-group col-md-2 px-2 mt-auto'>
                            <input type="button" className='btn btn-primary px-4' value='Filter'  onClick={(e) => setDateFilter(true)} />
                        </div>
                    </div>
                </div>
            <div className='row'>
                <div className='form-group row mt-2'>
                {/* <h2 className='fw-bold text-center'>Sign up and broker approved count</h2> */}
                <div className='form-group mt-3 d-flex'>
                 <div className='col-md-6 row align-items-end' style={{marginLeft:"8%"}}> 
                <PieChart label={["Sign Up",'Broker Approved']} data={pieData}/>
                </div>  
                <div className='ms-4 mt-5'>
                <ul className='border border-solid alert  justify-content-center  rounded fw-bolder fs-4' style={{ listStyleType: 'none',backgroundColor: '#EFEFEF'}}>
                    <div className='d-flex pb-3 ps-3 align-items-center'>
                    <div className="my-2 rounded-circle"  style={{"width": "11px", "height":"11px", "background":"#FF6384"}}></div>
                    <li className='my-1 ps-3'>Total Sign Ups - {pieData[0] ?? 0}</li>
                    </div>
                    <div className='d-flex pb-3 ps-3 align-items-center'>
                    <div className="my-2 rounded-circle"  style={{"width": "10px", "height":"10px", "background":"#36A2EB"}}></div>
                    <li className='my-1 ps-3'>Broker Approved - {pieData[1] ?? 0}</li>
                    </div>
                    <div className='d-flex pb-3 ps-3 align-items-center'>
                    <div className="my-2 rounded-circle "  style={{"width": "10px", "height":"10px", "background":"#4BC0C0"}}></div>
                    <li className=' my-1 ps-3 '>Buyer Lead - {leadData[0] ?? 0}</li>
                    </div>
                    <div className='d-flex pb-3 ps-3 align-items-center'>
                    <div className="my-2 rounded-circle "  style={{"width": "10px", "height":"10px", "background":"#9966FF"}}></div>
                    <li className='my-1 ps-3'>Seller Lead - {leadData[1] ?? 0}</li>
                    </div>
                    <div className='d-flex pb-3 ps-3 align-items-center'>
                    <div className="my-2 rounded-circle "  style={{"width": "10px", "height":"10px", "background":"#FF9F40"}}></div>
                    <li className='my-1 ps-3 '>Buyer Lead Visit Done - {leadData[2] ?? 0}</li>
                    </div>
                    <div className='d-flex ps-3 align-items-center'>
                    <div className="my-2 rounded-circle "  style={{"width": "10px", "height":"10px", "background":"#FFCE56"}}></div>
                    <li className='my-1 ps-3 '>Seller Lead Visit Done - {leadData[3] ?? 0}</li>
                    </div>
                </ul>
                </div>
                </div>
                
                </div>
                
                <div className='mt-3 justify-content-center'>
                <h1 className='fw-bold'>Leads count</h1>
                <BarChart label={label} data={data}/>
                </div>
                
            </div>
    </div>
    </ContentLayout>
    : 
    <Popup open={open} close={setOpen}>
        <Formik
          initialValues={{
            username: "",
          }}
          onSubmit={(values, event) => {
            userValid(values, event);
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup label="Username" required>
                <Field
                  placeholder="Username"
                  name="username"
                  className="form-control"
                />
                <ErrorField>
                  <ErrorMessage name="username" />
                </ErrorField>
              </FormGroup>

              <div className="d-grid">
                <Button type="submit">Enter User ID</Button>
              </div>
            </Form>
          )}
        </Formik>
      </Popup>
    }
    </>
    )
}