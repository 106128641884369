import React, {useEffect, useState} from 'react'
import Ajax from '../../../../../helpers/Ajax'
import TableLoader from '../../../../UI/Dashboard/TableLoader';
import DataView from '../../../../UI/Dashboard/DataView';
import TableContainer from '../../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../../Common/ContentLayout';
import EditButton from '../../../../UI/Dashboard/EditButton';
import DeleteButton from '../../../../UI/Dashboard/DeleteButton';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import PaginationContainer from '../../../../UI/Dashboard/PaginationContainer';
import Pagination from '../../../../UI/Dashboard/Pagination';
import FilterLayout from '../../../../UI/Dashboard/FilterLayout';
import { toast } from 'react-toastify';
import Button from '../../../../UI/Form/Button'
import FormGroup from '../../../../UI/Form/FormGroup'

export default function AmenityList() {
    const [dataList, setDataList] = useState(null)
    const [totalResult, setTotalResult] = useState(null)
    const [totalRow, setTotalRows] = useState(null)
    const [newpage, setNewPage] = useState(false)
    const [search, setSearch] = useState(false)
    const [initialPage, setInitialPage] = useState(0)
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(searchParams.get('page'))
    const pageParams = searchParams.get('page')
    const filterParams = searchParams.get('search')
    const navigate = useNavigate()
    useEffect(()=>{
        (async()=>{
        const response = await Ajax({
            url: `/developer/getDeveloperList${newpage !== false ? `?pagination=${newpage}` : ''}${pageParams && newpage === false ? `?pagination=${pageParams}` : ''}${filterParams ? `&search=${filterParams}` : ""}`,
            token: true,
            ...(pageParams && {loader: true})
        })
        if(response.data.status === "SUCCESS!"){
            setDataList(response.data.result)
            setTotalResult(response.data.countData)
            setTotalRows(response.data.countData / 10)
            setInitialPage(pageParams)
            setCurrentPage(searchParams.get('page'))
        }
        })()
    },[pageParams,filterParams])

    const [filterKey, setFilterKey] = useState({})
    const setFilter = (e) => {
        const fieldName = e.target.getAttribute("name")
        let updatedValue = {};
        if(e.target.value.trim().length === 0) {
            delete filterKey[fieldName]
            return false
        }
        updatedValue[fieldName] = e.target.value.trim()
        setFilterKey(oldValue => ({...oldValue, ...updatedValue}));
    }

    const filterHandler = async() => {
        navigate(`/admin/property-management/developer?page=0${filterKey && Object.keys(filterKey).length ? `&search=${filterKey.search ? filterKey.search : ""}` : ''}`)
        // const filterResponse = await Ajax({
        //     url: '/developer/getDeveloperList?' + new URLSearchParams(filterKey),
        //     token: true,
        //     loader: true
        // })
        // if(filterResponse.data.result.length === 0){
        //     setDataList(filterResponse.data.result)
        //     setCurrentPage(0)
        //     setInitialPage(0)
        //     setDataList(filterResponse.data.result)
        //     setTotalRows(filterResponse.data.countData / 10)
        //     setTotalResult(filterResponse.data.countData)
        //     toast.error('Matching Record not found!',{
        //         autoClose: 1500
        //     })
        //     setSearch(true)
        // }else{
            // setCurrentPage(0)
            // setInitialPage(0)
        //     setDataList(filterResponse.data.result)
        //     setTotalRows(filterResponse.data.countData / 10)
        //     setTotalResult(filterResponse.data.countData)
            // return false
        // }
    }

    return (
    <ContentLayout title='Developer' addNew='/admin/property-management/add-developer'>
        <div className="row">
        <Helmet>
            <title>Developer</title>
        </Helmet>
        <FilterLayout>
            <div className='row'>
                <div className='col-md-3'>
                    <FormGroup label="Developer Name">
                        <input type="text" name="search" placeholder="Developer Name" onChange={setFilter} className="form-control" />
                    </FormGroup>
                </div>
            </div>
            <div className="col-md-2 d-grid">
                <Button type="button" icon="fas fa-filter" onclick={filterHandler}>Filter</Button>
            </div>
        </FilterLayout>

        <DataView title="Developer List">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-start">Title</td>
                        <td className="text-start">State</td>
                        <td className="text-start">City</td>
                        <td className="text-start">Location</td>
                        <td className="text-start">Image</td>
                        <td className="text-start">Sort Order</td>
                        <td className="text-center">Active</td>
                        <td className="text-center">Edit</td>
                        {/* <td className="text-center">Delete</td> */}
                     
                    </tr>
                </thead>
                <tbody>
                    {dataList == null ? 
                    <TableLoader cols={9}/> : dataList.length === 0 ? 
                    <tr>
                        <td colSpan={9} className="text-center">Data not available</td>
                    </tr> : dataList.map((data, index)=>(
                    <tr key={data._id} >
                        <td className="text-center">{currentPage ? ((index + 1) + Number(currentPage) * 1 * 10) : index + 1}</td>
                        <td className="text-start text-capitalize">{data.name?data.name:''}</td>
                        <td className="text-start ">{data.state?data.state.name:''}</td>
                        <td className="text-start ">{data.city?data.city.name:''}</td>
                        <td className="text-start ">{data.location?data.location.name:''}</td>
                        <td className="text-start"><img src={data.file?data.file.path:''} alt="icon" /></td>
                        <td className="text-start">{data.sortOrder?data.sortOrder:0}</td>

                        <td className="text-center">{data.active === true ? <span className="badge bg-success">Enable</span> : <span className="badge bg-danger">Disable</span>}</td>
                        <td className="text-center">
                            <EditButton>{`/admin/property-management/developer/${data._id}`}</EditButton>
                        </td>
                        {/* <td className="text-center">
                            <DeleteButton url="/developer" id={data._id} />
                        </td> */}
                       
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
            <PaginationContainer count={totalResult}>
                <Pagination totalPage={totalRow} url='/admin/property-management/developer' currentPage={initialPage} filter={filterKey ? `search=${filterKey.search ? filterKey.search : ""}` : "" } />
            </PaginationContainer>
        </DataView>
    </div>
    </ContentLayout>
    )
}
